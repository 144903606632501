.how-we-started-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin: 20px auto 0;
  min-height: calc(100vh - 200px);
}

.how-we-started-heading {
  margin: 10px 0;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.how-we-started-heading h1 {
  margin: 10px 0;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}

.how-we-started-heading .heading-underline {
  border: solid #fed136;
  width: 250px;
  background-color: #fed136;
}

.how-we-started-about {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  gap: 50px;
}

.how-we-started-text {
  text-align: justify;
  width: 100%;
  color: #000000aa;
}

.how-we-started-text .texts1 {
  margin-bottom: 0.6rem;
}


.how-we-started-text .texts b {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #000000;
}


.how-we-started-img {
  width: 100%;
  max-width: 450px;
  height: 330px;
  /* margin: 2rem; */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.how-we-started-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 100px) {
  .how-we-started-container {
    margin: 12px auto 0;
    text-align: left;
  }

  .how-we-started-img {
    /* margin-right: 20px; */
    width: auto;
  }
}


/* Media Queries for Responsive Design */

@media screen and (max-width: 768px) {
  .how-we-started-container {
    margin: 14px auto 0;
    text-align: left;
  }

  .how-we-started-about {
    gap: 30px;
    flex-direction: column-reverse;
  }

  .how-we-started-text {
    max-width: 500px;
  }

  .how-we-started-image {
    height: 300px;
    width: 100%;
  }
}

b {
  font-weight: 500 !important;
}