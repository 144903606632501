.feedindiapage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 10px auto;
    text-align: justify;
    flex-direction: row-reverse;
}

.infofeed {
    padding: 1rem;
    margin: 10px;
    width: 100%;
}

.infofeed h2 {
    font-size: 1.6rem;
    font-weight: 700;
    color: #202020;
}

.descriptionfeed {
    padding-top: 1rem;
    color: #202020;
    font-size: 1.1rem;
}

.namefeed {
    color: #695E91;
}

.guest-image {
    max-width: 450px;
    margin: 1rem;
}

.hide-image {
    display: none;
}

.guest-image img {
    width: 100%;
    border-radius: 2rem;
    text-align: center;
    border-left: 10px solid #695E91;
    border-bottom: 10px solid #695E91;
    border-top: 2px solid #695E91;
    border-right: 2px solid #695E91;
    align-items: center;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:980px) {
    .feedindiapage {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        width: 100%;
        margin: 10px auto;
    }

    .descriptionfeed {
        padding: 20px;
        padding-top: 0;
    }

    .infofeed {
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
    .titlefeed {
        padding: 20px;
    }

    .guest-image {
        display: none;
    }

    .hide-image {
        display: block;
    }

    .image img {
        width: 95%;
        border-radius: 2vmax;
        padding-top: 2px;
        margin: 5px 0px;
        display: flex;
        justify-content: space-evenly;
    }
}

@media (max-width:1200px) {
    .feedindiapage {
        padding: 5px;

    }
}

@media (max-width:500px) {
    .feedindiapage {
        padding: 2px;
        flex-direction: column-reverse;
    }

    .image img {
        max-width: 100%;
        border-radius: 2vmax;
        padding-top: 6px;
        margin: 5px 0px;
        display: flex;
        justify-content: space-evenly;
    }

    .infofeed {
        padding: 2px;
    }

}