.report_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 20px auto 0;
    min-height: calc(100vh - 100px);
}

.report_container .report_heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
}

.report_container .report_cards_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.report_cards_container .card {
    max-width: 400px;
    min-height: 450px;
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    transition: all 0.15s ease-in 0s;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.report_cards_container .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.card .card_header {
    font-size: 1.2rem;
    margin: 1rem 0;
    text-align: center;
    color: #f26a36;
    letter-spacing: 0.5px;
}

.card .card_body {
    text-align: center;
    font-size: 1rem;
    color: #444;
    margin: 1rem 0;
    text-shadow: none;
}

.card .button_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 1.2rem 0;
}


.button_container .button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #fed136;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.7rem 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-in;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.quaterly-finance-report .button_container a {
    max-width: 200px;
    width: 100%;
}

.button_container .button:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    color: #fff;
    font-weight: 600;
}

@media (max-width: 768px) {
    .report_container .report_heading {
        font-size: 1.2rem;
        margin: 1rem;
    }
}

@media (max-width:1160px) {
    .report_container {
        margin: 20px;
    }

    .report_cards_container {
        flex-direction: column;
    }

    .report_cards_container .card {
        min-height: auto;

    }
}

@media screen and (max-width: 400px) {
    .report_cards_container .card {
        padding: 0.5rem;
        margin: 1rem 0rem;
    }

}