.sliders-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-slider {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
}


.container-slider-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

}

a.slider-card {
    text-decoration: none;
    height: 270px;
    padding: 0 10px;
    margin: 0.8rem;
    display: flex;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: space-evenly;
    color: black;
    background-color: #fff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);

}

.slider-card-image {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.slider-card-image img {
    width: 100%;
    object-fit: cover;
}


@media (min-width:1500px) {
    .slider-card {
        /* margin: auto;
          width: 150px; */
        height: auto;
    }

}

@media (min-width:500px) and (max-width:1000px) {
    .slider-card img {
        width: 100%;
        /* height: 100%; */
        padding: 1px;
        margin: 1px;
    }
}

@media (max-width:500px) {
    .sliderContainer {
        max-height: 1000px;
        position: relative;
    }

    .slider-card {
        padding: 0px 2px;
    }

    .slider-card img {
        width: 100%;
        /* height: 100%; */
        height: auto;
        padding: 1px;
        margin: 1px;
    }
}


.slick-prev,
.slick-next {
    display: none !important;
}