.about-accordion-container * {
    transition: all 0.3s ease;
}

.about-accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 800px;
    margin: 2rem auto 4rem;
    min-height: 70vh;
}


.about-accordion-container h1 {
    margin: 10px 0;
    font-weight: 900;
    text-align: center;
    font-size: 2rem;
}

.about-accordion-container .heading-underline {
    border: solid #fed136;
    width: 100px;
    background-color: #fed136;
    margin-bottom: 2rem
}

.accordion-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 1rem 0;
    gap: 1rem;
}

.accordion-container .accordion-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}


.accordion-item .accordion-header {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f26a36;
    border-radius: 1rem;
    transition: all 0.4s ease-in-out;

}

.accordion-header-question {
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
}

.accordion-header-icon {
    color: #fff;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.accordion-header-icon.open {
    transform: rotate(45deg);
}

.accordion-item .accordion-content {
    width: 100%;
    padding: 1rem;
    background-color: #fff;
}