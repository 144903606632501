nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f26a36;
  color: #fff;
  height: 90px;
  width: 100%;
  z-index: 1000;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  box-shadow: 0px 0px 10px 0px #0000004f;
}

nav>a {
  color: white;
  text-decoration: none;
}

.logo {
  font-size: 25px;
  font-weight: 600;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .logo img {
  width: 100%;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menu-toggle.open {
  transform: rotate(180deg);
}

.HiMenu {
  transform: scale(1.2);
}

.nav-links {
  list-style: none;
  display: flex;
  margin: auto 0px;
  font-size: 15px;
  gap: 20px;
}

.nav-links li {
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  letter-spacing: 0.4px;
  
}

.nav-links a{
  text-decoration: none;
  color: white;

}
.nav-links a {
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #f26a36;
}

.nav-links li:hover {
  color: #fed136;
}

.active{
  border-bottom: 3px solid #fed136 !important;
}
#donatebtn {
  transition: all 0.2s ease-out;
}

#donatebtn {
  background-color: #fed136;
  border-radius: 5px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 11px 14px;
  transition: all 0.2s ease-out;
  height: 40px;
}

#donatebtn:hover {
  transform: scale(1.04);
  box-shadow: 0px 0px 10px 0px #73c48f;
  color: #000 !important;
}
#donatebtn li:hover {
  color: #000 !important;
}

@media (max-width: 1150px) {
  .logo {
    font-size: 20px;
    letter-spacing: 0px;
  }
}

@media (max-width: 1024px) {
  nav {
    height: 90px;
  }

  .dropdown1-content {
    display: none;
    position: relative !important;
    top: 20px;

    background-color: #4b4276;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
  }


  .logo {
    font-size: 23px;
    letter-spacing: 0px;
  }

  .nav-links {
    border-top: solid rgba(255, 255, 255, 0.271);
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #f26a36;
    line-height: 30px;
    /* margin: -7px 0px; */
    padding: 20px 10px;
    position: fixed;
    top: 90px;
    right: 0;
    width: 100%;
    min-height: 50vh;
    text-align: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 999;
    border-radius: 0px 0px 5px 5px;
    opacity: 0.5;
  }
  .active{
    border: 3px solid #f26a36 !important;
  }
  .active li{
    
    color: #fed136 !important;
  }
  .nav_space {
    height: 90px;
  }

  .nav-links.show {
    transform: translateX(0);
    opacity: 1;
    height: calc(100vh - 70px);
    padding-bottom: 5rem;
  }

  .menu-toggle {
    display: flex;
    align-items: center;
  }

  .HiMenu {
    font-size: 22px;
  }

  .nav-links {
    line-height: 50px;
    font-size: 18px;
    letter-spacing: 1px;
  }

  .nav-links li {
    font-weight: 600;
    color: #fff;
    margin: 0px 10px;

    text-transform: uppercase;
    text-shadow: 2px 2px 5px #0000004f;
    border-radius: 10px;
    cursor: pointer;
  }

  #donatebtn:hover {
    box-shadow: none;
  }
  #donatebtn{
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  nav {
    padding: 0 10px;
    height: 70px;
  }

  .nav_space {
    height: 69px;
  }

  .logo {
    font-size: 15px;
    letter-spacing: 0px;
  }

  .logoImg {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }

  .menu-toggle,
  .HiMenu {
    font-size: 20px;
  }

  .nav-links {
    top: 70px;
  }
}

