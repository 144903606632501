.wrapper {
  display: flex;
  position: relative;
}

.wrapper .sidebar {
  padding-top: 20px;
  width: 350px;
  height: 100dvh;
  background: #4b4276;
  position: sticky;
  top: 90px;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.wrapper .sidebar h2 {
  color: #fed136;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0;
}

.wrapper ul {
  list-style: none;
}

.wrapper .sidebar ul li {
  text-align: center;
  padding: 10px 10px;
  border-bottom: 1px solid #bdb8d7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.wrapper .sidebar ul a {
  text-decoration: none;
  color: #ffffff;
  display: block;
}

.wrapper .sidebar ul li:hover {
  background-color: #594f8d;
}

.wrapper .sidebar ul li:hover {
  color: #ffffff;
}

.wrapper .sidebar .social_media {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.wrapper .sidebar .social_media a {
  display: block;
  width: 40px;
  background: #594f8d;
  height: 40px;
  line-height: 45px;
  text-align: center;
  margin: 0 5px;
  color: #bdb8d7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.main_content {
  width: 100%;
  padding: 2rem 10px 4rem;
}

.main_content .header {
  padding: 20px;
  background: #840975;
  color: #717171;
  border-bottom: 1px solid #e0e4e8;
}

@media (max-height: 500px) {
  .social_media {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    display: flex;
    /* position: ; */
  }

  .wrapper .sidebar {
    padding-top: 0px;
    position: fixed;
    width: 100lvw;
    z-index: 11;
    top: 90px !important;
    height: 100lvh;
    opacity: 0.96;
  }

  .wrapper .sidebar h2 {
    margin: 30px 0 !important;
  }
}

@media (max-width: 767px) {
  .wrapper .sidebar {
    top: 69px !important;
  }

  .hamburger {
    top: 71px !important;
    left: 15px !important;
  }
}

/* Members.css */

.dropdown {
  position: relative;
  color: white;
}

.jack {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid red; */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.20s ease-in 0s;
}

.jack:hover {
  transform: translateY(-5px);
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  background-color: #4b4276;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  width: 94%;
}

.dropdown-content li {
  border-radius: 5px;
}

/* 
.dropdown:hover .dropdown-content {
  display: block;
} */

.sidebar.collapsed {
  width: 0px;
}

.hamburger {
  position: fixed;
  top: 120px;
  left: 30px;
  z-index: 101;
  width: 50px;
  height: 50px;

  border-radius: 50%;
  /* background-color: white; */
  border: 2px solid #594f8d;
  backdrop-filter: blur(2px) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.15s ease-in 0s;
  font-size: 18px;
  font-weight: 600;
}

.hamburger::after {
  content: " ";
  border: 2px solid #594f8d;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: move 1s ease infinite;
  /* transition: all ease 2s; */
}

@keyframes move {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale(1.3);
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: scale(1);
  }
}

.hamburger a {
  text-decoration: none;
  color: black;
}

.hamburger:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

.sidebar.collapsed .everything {
  display: none;
}

.hamburger:hover a {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}