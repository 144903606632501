.light-donate-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    margin: 0 auto;
    background-color: #e9e9e9;
}

.light-donate-card {
    width: 100%;
    padding: 2rem 1rem;
    background-color: #fff;
    text-align: justify;
    position: relative;
}



.pay-btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.light-donate-card-title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 auto;
    max-width: 1000px;
}

.light-donate-card-centre {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 0.8rem;
    max-width: 1000px;
}


.light-donate-card-centre a {
    text-decoration: none;
    color: #f26a36;
}

.light-donate-card-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1rem;
    max-width: 1000px;
    margin: 0 auto;
}

@media (max-width:500px) {
    .light-donate-card-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .light-donate-card-centre {
        font-size: 1.4rem;
        font-weight: 550;
        margin: 0 auto;
    }

    .light-donate-card-description {
        font-size: 1rem;
        margin: 0.5rem auto;
    }
}