.container {
    display: flex;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}

.mycontainer {
    display: flex;
    max-width: 1200px;
    align-items: center;
    width: 100%;
    /* gap: 1rem; */
    justify-content: space-around;
}

/* @media (max-width:1200px) {
    .mycontainer {
        margin: 20px;
    }
} */

.mainbox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    border-radius: 1rem;
    padding: 1rem;

    width: 100%;
    max-width: 260px;
    min-height: 300px;
    background-color: #fff;
    /* background-color: black; */
    transition: all 0.15s ease-in-out;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    margin: 20px;
}

.mainbox:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

#first {
    border: 3px solid #05A1C790;
}


#second {
    border: 3px solid #05A1C790;
}

#third {
    border: 3px solid #F16A3690;
}

#fourth {
    border: 3px solid #F16A3690;
}

.mainbox img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 3px lightgray;
}

.discr {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}






@media (max-width:980px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .mycontainer {
        display: flex;
        justify-content: space-evenly;
    }
}

@media (max-width:580px) {
    .DusraContainer {
        flex-direction: column;
    }

    .mainbox {
        max-width: 290px;
    }
}

@media (max-width:500px) {
    .mycontainer {
        display: flex;
        flex-direction: column;
    }


    .mainbox {
        margin: 1rem;
    }
}