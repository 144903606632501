@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&subset=latin-ext");

.donate-page-container {
  padding: 1rem;
}

.blog-item img {
  /* display: block; */
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog-item a {
  display: flex;
  padding: 25px;
  overflow: hidden;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  color: #333;
  /* font-family: "Lato", sans-serif; */
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 50px;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid blue;
}

.blog-item a:hover {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-item .icon {
  width: 35%;
  object-fit: cover;
  margin-right: 50px;
  border-radius: 6px;
  overflow: hidden;
  /* box-shadow: 0 15px 26px rgba(0, 0, 0, 0.4); */
}

.blog-item .content {
  width: 65%;
}

.blog-item .content .title {
  font-size: 21px;
  text-transform: capitalize;
  color: #333;
  font-weight: 600;
  /* font-family: "Lato", sans-serif; */
}

.blog-item .content .title .blog-date {
  font-size: 12px;
  margin-left: 20px;
  color: #333;
  /* font-family: "Lato", sans-serif; */
}

.blog-item .content .title .blog-date:before {
  content: "/";
  color: #fed136;
  font-size: 25px;
  top: auto;
  position: relative;
  margin-right: 10px;
}

.blog-item .content .rounded {
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog-item .item-arrow {
  height: 20px;
  width: 15%;
  position: relative;
  text-align: center;
  font-size: 35px;
  left: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-item a:hover>.item-arrow {
  /*padding-left: 40px;*/
  color: #f26a36;
  left: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.rounded {
  width: 70px;
  height: 5px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #fed136, #fed136);
  /* background: #2bb769; */
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .blog-item .content {
    width: 100%;
    margin-top: 35px;
  }

  .blog-item .content .title {
    font-size: 18px;
  }

  .blog-item .item-arrow {
    margin: auto;
  }

  .blog-item .icon {
    width: 100%;
  }


  .blog-item {
    width: 92%;
    margin: auto;
  }

  .blog-item a {
    display: block;
    padding: 30px;
  }
}