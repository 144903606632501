.hcard-name {
    /* border: solid red; */
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.hcard-name h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
}

.line {
    height: 5px;
    width: 80%;
    margin-top: 10px;
    background-color: #fed136;
}

@media (max-width:385px) {
    .hcard-name h2 {
        font-size: 1.27em;
    }
}