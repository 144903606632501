.scroll_btn {
    display: none;
}

.scroll_button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99;
    width: 50px;
    height: 50px;

    border-radius: 50%;
    background-color: #fed136;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: all 0.15s ease-in 0s;
    font-size: 18px;
    font-weight: 600;

}

.scroll_button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
}
