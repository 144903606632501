.newsletter_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 4rem;
}


.newsletter_card_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.newsletter_card {
    width: 100%;
    min-height: 250px;
    margin: 1rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.15s ease-in 0s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsletter_card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
}

.newsletter_card .newsletter_card_image {
    max-width: 160px;
    width: 100%;
    max-height: 225px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.newsletter_card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.newsletter_card_content {
    text-align: center;
    font-size: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    max-width: 900px;
    width: 100%;
}

.newsletter_card_content h2 {
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    text-align: center;
    color: #33236c;
    letter-spacing: 0.5px;
}

.newsletter_card_content p {
    text-align: center;
    font-size: 1rem;
    color: #444;
    margin: 1rem 0;
    text-shadow: none;
}

.newsletter_card_content a {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #fed136;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.20s ease-in 0s;
}

.newsletter_card_content a:hover {
    color: #fff;
    transform: translateY(-5px);
}

@media screen and (max-width: 968px) {
    .newsletter_card_content h2 {
        margin: 0 0 0rem 0;
    }

    .newsletter_card_content p {
        margin: 0.5rem 0;
    }
}

@media (max-width: 520px) {

    .newsletter_card {
        width: 100%;
        margin: 1rem;
        flex-direction: column;
    }
}